/* 

radioactive purples background: linear-gradient(180deg, #131B68 24.44%, rgba(255, 0, 164, 0.6) 140.45%);
smokey blue gradient background: linear-gradient(180deg, rgba(111, 122, 192, 0) -55.83%, #151A3A 117.22%);
grad blue #131B68
blueish black #191932
light purple #6A6A9F

*/

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
    font-family: 'Avenir LT Pro';
    font-style: normal;
    font-weight: 500;
    src: local('Avenir LT Pro 45 Book'),
      url('./assets/fonts/AvenirLTProBook.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    font-style: italic;
    font-weight: 500;
    src: local('Avenir LT Pro 45 Book Oblique'),
      url('./assets/fonts/AvenirLTProBookOblique.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    font-style: normal;
    font-weight: 600;
    src: local('Avenir LT Pro 55 Roman'),
      url('./assets/fonts/AvenirLTProRoman.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    font-style: italic;
    font-weight: 600;
    src: local('Avenir LT Pro 55 Oblique'),
      url('./assets/fonts/AvenirLTProOblique.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Avenir LT Pro 35 Light'),
      url('./assets/fonts/AvenirLTProLight.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    font-style: italic;
    font-weight: 400;
    src: local('Avenir LT Pro 35 Light Oblique'),
      url('./assets/fonts/AvenirLTProLightOblique.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    font-style: normal;
    font-weight: 650;
    src: local('Avenir LT Pro 65 Medium'),
      url('./assets/fonts/AvenirLTProMedium.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    font-style: italic;
    font-weight: 650;
    src: local('Avenir LT Pro 65 Medium Oblique'),
      url('./assets/fonts/AvenirLTProMediumOblique.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    font-style: normal;
    font-weight: 900;
    src: local('Avenir LT Pro 85 Heavy'),
      url('./assets/fonts/AvenirLTProHeavy.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    font-style: italic;
    font-weight: 900;
    src: local('Avenir LT Pro 85 Heavy Oblique'),
      url('./assets/fonts/AvenirLTProHeavyOblique.woff') format('woff');
  }

body {
  font-family: 'Avenir LT Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
}
